<template>
  <div>
    <div v-if="userBannedUntil" class="suspended">
      <p class="mb-2">User is suspended until {{ formatDate(userBannedUntil) }}</p>
      <p><u>Moderator note</u>{{'\n'+ suspensionInfo?.reason}}</p>
    </div>
    <div v-if="notifications.length && !loading" class="notifications d-flex align-items-center flex-column">
      <notification-card
        v-for="(notification, index) of notifications"
        :key="index"
        :notification="notification"
        class="my-3"
        @refetch="refetch"
      />
      <ion-button v-if="nextPageExists" class="load-more-btn d-flex justify-content-center" @click="requestLoadMore">
        <div class="d-flex align-items-center justify-content-center">
          <div v-if="hiddenUnreadCount" class="ping" />
          <ChLoading size="sm" v-if="isLoading" class="spinner my-1" />
          <p v-else class="clickable-item-hov my-1">Load More</p>
        </div>
      </ion-button>
    </div>
    <div v-else-if="loading" class="no-data d-flex w-100 justify-content-center">
      <ChLoading size="lg" />
    </div>
    <div v-else class="no-data">No notifications</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Action, Getter } from 's-vuex-class';
import NotificationCard from '@/shared/components/NotificationCard.vue';
import namespace from '@/shared/store/namespace';
import { authStore } from '@/shared/pinia-store/auth';
import { formatDate } from '@/shared/utils/dateTime';

@Options({
  name: 'InfiniteNotifications',
  components: {
    NotificationCard,
  },
})
export default class InfiniteNotifications extends Vue {
  @Prop() public currentTab!: string;
  @Prop() public loading!: boolean;

  public isLoading = false;
  public suspensionInfo = null;

  public get nextPageExists() {
    return !!this.notificationsPaging.next;
  }

  public get hiddenUnreadCount() {
    return this.unreadCountFromAPI > this.notifications.length;
  }

  public get userBannedUntil() {
    const { userBannedUntil } = authStore();
    return userBannedUntil.value;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.notificationsPaging.next) {
      (ev?.target as any).complete();
    } else {
      this.isLoading = true;
      await this.notificationsNextPage();
      this.isLoading = false;
    }
  }

  @Emit('refetch')
  public refetch() {
    return true;
  }

  @Action('notificationsNextPage', { namespace: namespace.NotificationsModule })
  public notificationsNextPage!: any;

  @Getter('notifications', { namespace: namespace.NotificationsModule })
  public notifications!: any[];

  @Getter('unreadCountFromAPI', { namespace: namespace.NotificationsModule })
  public unreadCountFromAPI!: number;

  @Getter('notificationsPaging', { namespace: namespace.NotificationsModule })
  public notificationsPaging!: any;

  @Action('getNotifications', { namespace: namespace.NotificationsModule })
  public getNotifications!: any;

  async mounted() {
    const { getSuspensionInfo } = authStore();
    if (this.userBannedUntil) {
      this.suspensionInfo = await getSuspensionInfo();
    }
  }
}
</script>

<style scoped lang="sass">
@keyframes ping
  0%
    transform: scale(1)
    opacity: 0.5

  100%
    transform: scale(2)
    opacity: 0
.ping
  position: relative
  z-index: 11
  width: 20px
  height: 18px
.ping::before,
.ping::after
  background-color: #eb445a
  content: ''
  position: absolute
  top: 0.25em
  left: 0
  width: 0.75em
  height: 0.75em
  border-radius: 50%
.ping::before
  animation: ping 1.7s ease infinite
  opacity: 0.25
.load-more-btn
  width: 200px
  --border-radius: 10px
.notifications
  color: #214163
.suspended
  padding: 0.01rem 0.5rem !important
  border-radius: 8px
  background-color: #eb445a
  margin-bottom: 1rem
  color: white
  white-space: pre-wrap
</style>
